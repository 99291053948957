<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择设备"
    class="small-padding"
    width="1000px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="/elevator-iot/device/page">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="设备型号">
            <vm-dict-select v-model="filter.model" type="iotDeviceModel"></vm-dict-select>
          </vm-search>
          <vm-search label="设备编号">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="电信运营商">
            <vm-dict-select v-model="filter.isp" type="isp"></vm-dict-select>
          </vm-search>
          <vm-search label="">
            <span>自</span>
            <el-date-picker v-model.trim="filter.onlineSince" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" clearable></el-date-picker>
            <span>以来在线</span>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="设备名称/编号" align="center"></el-table-column>
      <el-table-column prop="iotProtocolSourceDesc" label="物联网协议来源" align="center"></el-table-column>
      <el-table-column prop="model" label="设备型号" align="center"></el-table-column>
      <el-table-column prop="ispDesc" label="电信运营商" align="center"></el-table-column>
      <el-table-column prop="simCode" label="SIM卡号" align="center"></el-table-column>
      <el-table-column prop="iccidCode" label="ICCID" align="center"></el-table-column>
      <el-table-column label="在线状态" align="center" width="90">
        <template slot-scope="scope">
          <el-tag :type="scope.row.online?'success':'info'">{{scope.row.online?'在线':'离线'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remoteAddress" label="网络地址" align="center"></el-table-column>
      <el-table-column label="上次心跳时间/接收数据时间" align="center">
        <template slot-scope="scope">
          {{scope.row.lastHeartbeatTime}}/{{scope.row.lastDataReceivedTime}}
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          status: "",
        },
      };
    },
    methods: {
      open() {
        this.filter={};
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row);
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
