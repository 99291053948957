<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择楼宇"
    class="small-padding"
    width="1000px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="real-estate/building/building/page">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼宇名称">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="developerCompanyName" label="开发商名称" align="center"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称" align="center"></el-table-column>
      <el-table-column prop="name" label="楼宇名称" align="center"></el-table-column>
      <el-table-column prop="usingCompanyName" label="使用单位名称" align="center"></el-table-column>
      <el-table-column prop="aboveGroundFloorCount" label="地上楼层计数" align="center"></el-table-column>
      <el-table-column prop="underGroundFloorCount" label="地下楼层计数" align="center"></el-table-column>
      <el-table-column prop="longitude" label="经度" align="center"></el-table-column>
      <el-table-column prop="latitude" label="纬度" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          status: "",
        },
      };
    },
    methods: {
      open() {
        this.filter={};
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row);
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
