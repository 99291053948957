<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择品牌"
    class="small-padding"
    width="1000px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/elevator-model/page">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('project.name','型号名称')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="品牌名称">
            <el-input v-model.trim="filter.brandName" clearable></el-input>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" :label="$l('user.name','电梯型号')" align="center"></el-table-column>
      <el-table-column prop="brandName" :label="$l('user.name','电梯品牌')" align="center"></el-table-column>
      <el-table-column prop="categoryDesc" label="电梯类别" align="center"></el-table-column>
      <el-table-column prop="varietyDesc" label="电梯品种" align="center"></el-table-column>
      <el-table-column prop="controlSystemDesc" label="控制系统" align="center"></el-table-column>
      <el-table-column prop="drivingModeDesc" label="驱动方式" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          status: "",
        },
      };
    },
    methods: {
      open(name) {
        this.dialogVisible = true;
        if (name) this.filter.brandName=name;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row);
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
