<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑电梯档案':'新增电梯档案'"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="formFillWidth"
      :label-width="$l('elevator.labelWidth','110px')">
      <el-tabs v-model="tabActive" type="card">
        <el-tab-pane label="基本信息" name="first">
          <div class="vm-separate">
            <el-form-item :label="$l('project.name','制造商')" prop="manufacturerName">
              <vm-select-input :value="model.manufacturerName" @select="$refs.manufacturerSelect.open()"></vm-select-input>
            </el-form-item>
            <el-form-item :label="$l('project.name','品牌')" prop="brandName">
              <vm-select-input :value="model.brandName" @select="$refs.brandSelect.open(model.manufacturerName)"></vm-select-input>
            </el-form-item>
            <el-form-item :label="$l('project.name','型号')" prop="modelId">
              <vm-select-input :value="model.modelName" @select="$refs.modalSelect.open(model.brandName)"></vm-select-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item label="电梯类别" prop="category">
              <vm-dict-select v-model="model.category" type="elevatorCategory" disabled></vm-dict-select>
            </el-form-item>
            <el-form-item label="电梯品种" prop="variety">
              <vm-dict-select v-model="model.variety" type="elevatorVariety" disabled></vm-dict-select>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item label="控制系统" prop="controlSystem">
              <vm-dict-select v-model="model.controlSystem" type="elevatorControlSystem" disabled></vm-dict-select>
            </el-form-item>
            <el-form-item label="驱动方式" prop="drivingMode">
              <vm-dict-select v-model="model.drivingMode" type="elevatorDrivingMode" disabled></vm-dict-select>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$l('elevator.name','产品出厂编号')" psrop="factoryCode">
              <el-input v-model="model.factoryCode" :placeholder="$l('common.enter','产品合格证上标示的设备编号')"></el-input>
            </el-form-item>
            <el-form-item :label="$l('elevator.name','出厂日期')" prop="factoryDate">
              <el-date-picker
                v-model="model.factoryDate"
                type="date"
                value-format="yyyy-MM-dd hh:mm:ss"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$l('project.name','楼盘')" prop="realEstateName">
              <vm-select-input :value="model.realEstateName" @select="$refs.realEstateSelect.open()"></vm-select-input>
            </el-form-item>
            <el-form-item :label="$l('project.name','楼宇')" prop="buildingName">
              <vm-select-input :value="model.buildingName" @select="$refs.buildingSelect.open(model.buildingName)"></vm-select-input>
            </el-form-item>
            <el-form-item :label="$l('elevator.name','电梯名称')" psrop="name" :rules="$rule.notNull">
              <el-input v-model="model.name" :placeholder="$l('common.enter','产品合格证上标示的设备编号')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$l('device.no','救援识别码/位置码')" prop="useCode">
              <el-input v-model="model.rescueCode" clearable :placeholder="$l('common.enter','请输入')"></el-input>
            </el-form-item>
            <el-form-item label="注册代码" prop="regCode">
              <el-input v-model="model.regCode" clearable :placeholder="$l('common.enter','请输入')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item label="使用场所分类" prop="usePlaceCategory">
              <vm-dict-select v-model="model.usePlaceCategory" type="elevatorUsePlaceCategory"></vm-dict-select>
            </el-form-item>
            <el-form-item :label="$l('device.no','开始使用日期')" prop="useDate">
              <el-date-picker
                v-model="model.useDate"
                type="date"
                value-format="yyyy-MM-dd hh:mm:ss"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item label="使用类型" prop="useType">
              <vm-dict-select v-model="model.useType" type="elevatorUseType"></vm-dict-select>
            </el-form-item>
            <el-form-item label="使用状态" prop="useStatus">
              <vm-dict-select v-model="model.useStatus" type="elevatorUseStatus"></vm-dict-select>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$l('device.no','电梯额定速度')" prop="ratedSpeed">
              <el-input v-model="model.ratedSpeed" clearable :placeholder="$l('common.enter','请输入')"></el-input>
            </el-form-item>
            <el-form-item :label="$l('device.no','控制系统')" prop="controlSystem">
              <vm-dict-select v-model="model.controlSystem" type="elevatorControlSystem"></vm-dict-select>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$l('device.no','电梯层数')" prop="totalFloor">
              <el-input v-model="model.totalFloor" clearable :placeholder="$l('common.enter','请输入')"></el-input>
            </el-form-item>
            <el-form-item label="电梯站数" prop="totalStation">
              <el-input v-model="model.totalStation" clearable :placeholder="$l('common.enter','请输入')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$l('device.no','电梯额定载重量')" prop="ratedLoads">
              <el-input v-model="model.ratedLoads" clearable :placeholder="$l('common.enter','请输入')"></el-input>
            </el-form-item>
            <el-form-item label="起升高度" prop="liftingHeight">
              <el-input v-model="model.liftingHeight" clearable :placeholder="$l('common.enter','请输入')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$l('device.no','顶层高度')" prop="topHeight">
              <el-input v-model="model.topHeight" clearable :placeholder="$l('common.enter','请输入')"></el-input>
            </el-form-item>
            <el-form-item label="底坑高度" prop="pitHeight">
              <el-input v-model="model.pitHeight" clearable :placeholder="$l('common.enter','请输入')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$l('device.ElevatorDoorOpenWay','开门方式')" prop="doorOpenWay">
              <vm-dict-select v-model="model.doorOpenWay" type="ElevatorDoorOpenWay"></vm-dict-select>
            </el-form-item>
            <el-form-item label="开门尺寸" prop="doorWidth">
              <el-input v-model="model.doorWidth" clearable :placeholder="$l('common.enter','请输入')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$l('device.no','是否有机房')" prop="machineRoom">
              <el-select v-model="model.machineRoom">
                <el-option :value="true" label="是"></el-option>
                <el-option :value="false" label="否"></el-option>
              </el-select>
            </el-form-item>
            <div></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="维保信息" name="second">
          <el-button type="primary" @click="add">新增</el-button>

          <el-table
            :data="model.maintenanceProjectItems"
            label-position="left"
            :row-class-name="rouClassNameFn"
            class="demo-table-expand"
          >
            <el-table-column align="scope.row.center" type="index" width="50"></el-table-column>

            <el-table-column label="维保项目" align="center" width="170">
              <template slot-scope="scope">
                <vm-select-input :value="scope.row.maintenanceProjectName" @select="$refs.projectSelect.open(scope.row.index)"></vm-select-input>
              </template>
            </el-table-column>

            <el-table-column prop="name" label="维保人员" align="center">
              <template slot-scope="scope">
                <vm-select-input :value="scope.row.assigneeName" @select="$refs.userSelect.open(scope.row.index)"></vm-select-input>
              </template>
            </el-table-column>
           
            <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="200">
              <template slot-scope="scope">
                <el-button type="danger" @click="del(scope.row)">{{$l("common.delete", "删除")}}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="物联网信息" name="third">
          <div class="vm-separate">
            <el-form-item :label="$l('device.no','物联网设备')" prop="elevatorIot.iotDeviceName">
              <vm-select-input :value="model.elevatorIot.iotDeviceName" @select="$refs.deviceSelect.open()"></vm-select-input>
            </el-form-item>
            <el-form-item label="物联网协议来源" prop="elevatorIot.iotProtocolSource">
              <vm-dict-select v-model="model.elevatorIot.iotProtocolSource" type="iotProtocolSource"></vm-dict-select>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item label="端口" prop="elevatorIot.iotDevicePort">
              <el-input-number
                v-model="model.elevatorIot.iotDevicePort"
                controls-position="right"
                :min="1"
                :max="65535"
                style="width: 100%"
                placeholder="戈尔设备请输入端口号"
                @change="onChange"></el-input-number>
            </el-form-item>
            <el-form-item label="物联网通信代码" prop="elevatorIot.iotCode">
              <el-input v-model="model.elevatorIot.iotCode" clearable :placeholder="$l('common.enter','请输入')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item label="设备型号" prop="elevatorIot.model">
              <vm-dict-select v-model="model.elevatorIot.model" type="iotDeviceModel" disabled></vm-dict-select>
            </el-form-item>

            <el-form-item label="电梯故障集" prop="iotFaultSet">
              <vm-dict-select v-model="model.elevatorIot.iotFaultSet" type="iotFaultSet"></vm-dict-select>
            </el-form-item>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <real-estate-select ref="realEstateSelect" @select="realEstateSelect"></real-estate-select>
    <building-select ref="buildingSelect" @select="buildingSelect"></building-select>
    <project-select ref="projectSelect" @select="projectSelect"></project-select>
    <brand-select ref="brandSelect" @select="brandSelect"></brand-select>
    <modal-select ref="modalSelect" @select="modalSelect"></modal-select>
    <manufacturer-select ref="manufacturerSelect" @select="manufacturerSelect"></manufacturer-select>
    <device-select ref="deviceSelect" @select="deviceSelect"></device-select>
    <user-select ref="userSelect" @select="selectWorker"></user-select>

    <event-template-select ref="faultTemplateSelect" @select="faultTemplateSelect"></event-template-select>
    <event-template-select ref="eventTemplateSelect" @select="eventTemplateSelect"></event-template-select>
  </el-dialog>
</template>

<script>
  // import VmPointMap from "@/components/VmPointMap";
  import UserSelect from "@/views/user/UserSelect";
  // import CompanySelect from "@/views/company/Select";
  import ProjectSelect from "@/views/project/ProjectSelect";
  import DeviceSelect from "@/views/device/DeviceSelect";
  // import ElevatorFloor from "@/views/elevator/ElevatorFloor";
  import EventTemplateSelect from "@/views/eventTemplate/Select";
  import brandSelect from  "@/views/basic/BrandSelect";
  import ModalSelect from "@/views/basic/ModalSelect";
  import ManufacturerSelect from "@/views/basic/ManufacturerSelect";
  import RealEstateSelect from "@/views/elevatorUsingRealEstate/RealEstateSelect";
  import BuildingSelect from "@/views/elevatorUsingBuilding/BuildingSelect";


  export default {
    components: { UserSelect,  ProjectSelect, DeviceSelect, EventTemplateSelect,brandSelect,ModalSelect,ManufacturerSelect,RealEstateSelect,BuildingSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        tabActive: "first",
        floorStart: 0,
        floorEnd: 0,
        model: {
          id: 0,
          projectName:"",
          elevatorIot:{},
          iotDevice:{},
          maintenanceProjectItems:[],
        },
      };
    },

    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`elevator-archive/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = {id:this.model.id,...data};
            console.log(this.model,280);
            if (!this.model.elevatorIot) {
              this.model.elevatorIot={};
            }
            // this.initMap();
          });
        } else {
          // this.initMap();
        }
      },
      initMap() {
        this.$nextTick(() => {
          this.$refs.map.initMap();
        });
      },
      projectSelect(row,index) {
        this.$set(this.model.maintenanceProjectItems[index],"maintenanceProjectId", row.id);
        this.$set(this.model.maintenanceProjectItems[index],"maintenanceProjectName", row.name);
      },
      brandSelect(row) {
        this.$set(this.model,"brandId", row.id);
        this.$set(this.model,"brandName", row.name);
      },

      modalSelect(row) {
        this.$set(this.model,"modelId", row.id);
        this.$set(this.model,"modelName", row.name);
        this.$set(this.model,"category", row.category);
        this.$set(this.model,"variety", row.variety);
        this.$set(this.model,"controlSystem", row.controlSystem);
        this.$set(this.model,"drivingMode", row.drivingMode);
      },
      manufacturerSelect(row) {
        this.$set(this.model,"manufacturerId", row.id);
        this.$set(this.model,"manufacturerName", row.name);

      },
      realEstateSelect(row) {
        this.$set(this.model,"realEstateId", row.id);
        this.$set(this.model,"realEstateName", row.name);

      },
      buildingSelect(row) {
        this.$set(this.model,"buildingId", row.id);
        this.$set(this.model,"buildingName", row.name);

      },

      deviceSelect(row) {
        this.$set(this.model.elevatorIot,"iotDeviceId", row.id);
        this.$set(this.model.elevatorIot,"iotDeviceName", row.name);
        this.$set(this.model.elevatorIot,"iotProtocolSource", row.iotProtocolSource);
        this.$set(this.model.elevatorIot,"remoteAddress", row.remoteAddress);
        this.$set(this.model.elevatorIot,"online", row.online);
        this.$set(this.model.elevatorIot,"lastHeartbeatTime", row.lastHeartbeatTime);
        if (row.iotProtocolSource==="ger") {
          this.$set(this.model.elevatorIot,"iotCode", row.name+":"+this.model.elevatorIot.iotDevicePort);
        }
      },
      selectWorker(row,index) {
        this.$set(this.model.maintenanceProjectItems[index],"assigneeId", row.id);
        this.$set(this.model.maintenanceProjectItems[index],"assigneeName", row.name);
      },

      clearDevice() {
        this.model.deviceId = 0;
        this.model.deviceNo = "";
      },
      faultTemplateSelect(row) {
        this.model.faultTemplateId = row.id;
        this.model.faultTemplateName = row.name;
      },
      eventTemplateSelect(row) {
        this.model.eventTemplateId = row.id;
        this.model.eventTemplateName = row.name;
      },
      clearFaultTemplate() {
        this.model.faultTemplateId = 0;
        this.model.faultTemplateName = "";
      },
      clearEventTemplate() {
        this.model.eventTemplateId = 0;
        this.model.eventTemplateName = "";
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;

            // return false;
            this.$http
              .save("elevator-archive", this.model)
              .then((res) => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                console.log(res,357);
                if (res===false) {
                  this.$message.error("保存失败");
                }else {
                  this.$message.success("保存成功");
                }
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      onChange(e) {

        if (this.model.elevatorIot.iotProtocolSource==="ger") {
          this.$set(this.model.elevatorIot,"iotCode", this.model.elevatorIot.iotDeviceName+":"+e);
        }

      },
      add() {
        this.model.maintenanceProjectItems.push({id:Math.random()});
      },
      del(row) {
        this.model.maintenanceProjectItems=this.model.maintenanceProjectItems.filter(v=>v.id!==row.id);
      },
      rouClassNameFn({row,rowIndex}) {
        row.index=rowIndex;
      },
      
    },
  };
</script>

<style lang="scss" scoped>
</style>
